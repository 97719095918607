var Flickity = require("flickity");
require('lity');
var svg4everybody = require('svg4everybody');


(function ($) {
  Drupal.behaviors.svg4everybodyBehavior = {
    attach: function(context, settings){
      $('body', context).once('svg4everybody').each(function(){
        svg4everybody();
      });
    }
  };

Drupal.behaviors.mediaEmbedBehavior = {
  attach: function(context, settings){
    $('.media_embed', context).once('mediaEmbed').each(function(){
      $(this).wrap('<div class="media_embed-wrapper"></div>');
    });
  }
};


Drupal.behaviors.bookingDateBehavior = {
  attach: function(context, settings){
    $('.webform-component--birthdate', context).once('bookingDate', function(){
      var $dateField = $(this).find('input');
      $dateField.pickadate({min: -365*100, selectYears: 100, max: -365*3});
    });
  }
};

Drupal.behaviors.dateFixBehavior = {
  attach: function(context, settings){
    $('.fix-dates', context).once('dateFix').each(function(){
      var $startDates = $(this).find('.start-date');
      var $startDates = $startDates.find('.date-display-single');
      $startDates.wrap('<div class="fancydates"></div>');

      $endDates = $(this).find('.end-date .date-display-single');

      var $fancydates = $(this).find('.fancydates');

      $endDates.each(function(index) {
        var string = $(this).text();
        console.log(index);
        console.log($fancydates.eq(index));
        $fancydates.eq(index).text($fancydates.eq(index).text() + ' - ' + string);
        $endDates.remove();
      });
    });
  }
};

Drupal.behaviors.stockBehavior = {
  attach: function(context, settings){
    $('.stockinfo', context).once('stock').each(function(){
      var stock = $(this).text().trim();
      if(parseInt(stock) < 0) {
        $(this).parent().find('form').replaceWith('<strong>Fully booked!</strong>');
      }
    });
  }
};





  Drupal.behaviors.oldBrowserBehavior = {
    attach: function(context, settings){
      $('body', context).once('oldBrowser').each(function(){
        if(Modernizr.flexbox) return false;
        var $warning = $('<div class="c-old-browser js-old-browser"> \
          <div class="c-old-browser__copy"> \
            <h6>You\'re using an outdated browser.</h6> \
            <p>Update your browser to view this site correctly.</p> \
          </div> \
          <div class="c-old-browser__options"> \
            <a class="c-old-browser__button c-old-browser__button--primary" id="btnUpdateBrowser" href="http://outdatedbrowser.com/">Update my browser now!</a> \
            <a class="last"><a href="#" class="c-old-browser__button c-old-browser__button--secondary js-close-old-browser" title="Close">&times;</a></p> \
          </div> \
        </div>');

        $warning.appendTo($('body'));
        $('.js-close-old-browser').click(function() {
          $warning.remove();
        });
      });
    }
  };

  Drupal.behaviors.objectFitBehavior = {
    attach: function(context, settings){
      // if(Modernizr.objectfit) return false;
      $('.object-fit').once('objectFit').each(function(){
        var $container = $(this);
        var $img = $(this).find('img');
        if(!$img.length) return false;
        $container.css('background-image', 'url('+$img.prop('src')+')');
        $img.remove();

      });
    }
  };

Drupal.behaviors.heroSliderBehavior = {
  attach: function(context, settings){
    $('.c-hero-slider', context).once('heroSlider').each(function(){
      var flikcityOptions = {
        wrapAround: true, autoPlay: 10000, cellSelector: '.c-hero-slider__slide',
        pageDots: false, prevNextButtons: false
      }
      var flickity = new Flickity($(this)[0], flikcityOptions);

      var $next = $(this).find('.c-hero-slider__nav--next');
      var $previous = $(this).find('.c-hero-slider__nav--previous');

      $next.click(function() {
        flickity.next();
      });

      $previous.click(function() {
        flickity.previous();
      });

    });
  }
};


Drupal.behaviors.placeholderBehavior = {
  attach: function(context, settings){
    $('.webform-component', context).once('placeholder').each(function(){
      var $input = $(this).find('input[type=text],input[type=email],input[type=password],textarea');
      var $label = $(this).find('label');

      if($input.length > 0 && $label.length > 0) {
        $input.attr('placeholder', $label.text());
        $label.addClass('visually-hidden');
      }
    });
  }
};

Drupal.behaviors.photoGalleryBehavior = {
  attach: function(context, settings){
    $('.c-photo-gallery', context).once('photoGallery').each(function(){
      $(this).find('.c-photo-gallery__item a').attr('data-lity', '');
    });
  }
};


Drupal.behaviors.openNewsletterBehavior = {
  attach: function(context, settings){
    $('.js-open-newsletter', context).once('openNewsletter').each(function(){
     $(this).click(function(e) {
      e.preventDefault();
      $('#newsletter').fadeIn(300);
     });
    });
  }
};

Drupal.behaviors.hideNewsletterBehavior = {
    attach: function(context, settings){
      $('.js-close-newsletter', context).once('hideNewsletter').each(function(){
      $(this).click(function() {
       $('#newsletter').fadeOut(300);
      });
      });
    }
  };


Drupal.behaviors.menuToggleBehavior = {
  attach: function(context, settings){
    $('.js-menu-toggle', context).once('menuToggle').each(function(){
      $('.c-main-menu').slideUp(0);
      var $toggle = $(this);
      var openText = $toggle.attr('data-open-label');
      var closeText = $toggle.attr('data-close-label');
      $(this).click(function() {
        $('.c-main-menu').slideToggle(300);

        if($toggle.text() == openText) {
          $toggle.text(closeText);
        } else {
          $toggle.text(openText);
        }

      });
    });
  }
};

Drupal.behaviors.commerce_fieldgroup_pane__group_extra_infoBehavior = {
  attach: function(context, settings){
    $('.commerce_fieldgroup_pane__group_extra_info, .checkout_review', context).once('commerce_fieldgroup_pane__group_extra_info').each(function(){
      var $types = $('#edit-cart-contents').find('.views-field-type');
      $types.hide(0);
      $('.commerce_fieldgroup_pane__group_extra_info').hide(0);
      var isHoliday = false;
      $types.each(function() {
        if($(this).text().trim() == 'Holiday') {
          isHoliday = true;
        $('.commerce_fieldgroup_pane__group_extra_info').show(0);
        }
      });

      if(!isHoliday) {
        var replace = 'I wish to take a cancellation insurance through Omnitravel (the travel agency with whom we are working)';
        var string = $('.pane-data:last-child .pane-data-full').text().split(replace).join('');
        $('.pane-data:last-child .pane-data-full').text(string);
        }
    });
  }
};

Drupal.behaviors.pressAttachmentBehavior = {
  attach: function(context, settings){
    $('.c-press-item__attachment', context).once('pressAttachment').each(function(){
      $(this).find('a').attr('target', '_blank');
    });
  }
};



})(jQuery);
